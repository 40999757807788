
































import { defineComponent } from "@vue/composition-api";
import SortPanel from "@monorepo/uikit/src/components/tableViews/SortPanel.vue";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import useSortPanelContainer from "@monorepo/utils/src/composables/useSortPanelContainer";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";

export default defineComponent({
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    additionalIds: {
      type: Object,
    },
    customFieldNames: {
      type: Object,
    },
    getQueryCb: {
      type: Function,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    defaultSortObj: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SortPanel,
    SelectFilter,
  },
  data() {
    return {
      sortType: SORT_TYPE.ASC,
      sortFilter: null as null | string,
      SORT_TYPE,
    };
  },
  computed: {
    sort(): Record<string, SORT_TYPE> {
      return this.$store.getters[`${this.sectionName}/${this.customFieldNames?.sort || "sort"}`];
    },
    sortItems(): any[] {
      return (this.tableHeaders as { isSorted: boolean; text: string; sortField?: string; value: string }[])
        .filter((header) => header.isSorted)
        .map((item: { text: string; sortField?: string; value: string }) => ({
          ...item,
          title: item.text,
          value: item.sortField || item.value,
        }));
    },
  },
  methods: {
    changeSortDirection() {
      this.sortType = this.sortType === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
      if (this.sortFilter) {
        this.changeSort(this.sortFilter);
      }
    },
    changeSort(id: string | null) {
      if (id && typeof id !== "object") {
        if (this.additionalIds?.[id]) {
          const resultSort = this.additionalIds?.[id].reduce((res: Record<string, SORT_TYPE>, current: string) => {
            res[current] = this.sortType;
            return res;
          }, {} as Record<string, SORT_TYPE>);
          this.addSort(resultSort);
        } else {
          this.addSort({ [id]: this.sortType });
        }
      } else {
        this.sortFilter = null;
        this.addSort(this.defaultSortObj);
      }
      this.refreshScrollDebounce();
    },
  },
  setup(props, { root }) {
    const { addSort, refreshScrollDebounce } = useSortPanelContainer(root, props.sectionName, props.id);

    return {
      addSort,
      refreshScrollDebounce,
    };
  },
});
