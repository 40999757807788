
























































































































































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ActsView/utils/convertApiItemToUi";
import { modalElements, mrpElements } from "@monorepo/utils/src/variables/projectsData/actsView/modalElements";
import DigitalSignaturesTable from "./DigitalSignaturesTable.vue";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/actsView/viewTitle";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { IActElement } from "@monorepo/inventory/src/views/ActsView/types/element";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import { Route } from "vue-router";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import CaseTableTree, { caseDtoItem } from "@monorepo/inventory/src/views/ActsView/components/CaseTableTree.vue";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";

export default defineComponent({
  name: "InventoryInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    DigitalSignaturesTable,
    ExportBtn,
    CardModalChapter,
    CaseTableTree,
    TxtViewer,
    TestImgPreview,
    DocumentTable: () =>
      import(
        /* webpackChunkName: "InventoryViewDocumentsTable" */
        "@monorepo/inventory/src/views/ActsView/components/DocumentTable.vue"
      ),
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      modalElements,
      mrpElements,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      viewUniqKey,
      openedPanels: [0, 1],
      openedDocument: "",
      section: Sections.INVENTORY,
      cardMode: CardMode,
      documents: {},
      fields,
      isLoading: false,
      digitalSignatures: [],
      isSaveCasesLoading: false,
      selectedCasesIds: {} as Record<string, boolean>,
      isShowPdf: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isExportFileLoading"]),
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("actsView", ["data", "openedId"]),
    isDisabledSave(): boolean {
      return !this.resultAvailableCases.length;
    },
    resultAvailableCases(): any[] {
      return (this.element.addedCases || []).concat(this.element.availableCasesToAdd || []);
    },
    exportList(): { title: string; type: "pdf" | "docx" }[] {
      return [
        {
          title: "PDF",
          type: "pdf",
        },
        {
          title: "DOCX",
          type: "docx",
        },
      ];
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowContentTooltip(): unknown {
      return (key: string) => {
        switch (key) {
          case this.fields.ACT_CASE_KIND:
          case this.fields.ACT_CASE_TYPE:
            return true;
          default:
            return false;
        }
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.CASE_ACT_EXPORT);
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          default:
            return get(this.element, key);
        }
      };
    },
    statusColor(): string {
      switch (this.element.status) {
        case "Отправлен в ОИК":
          return "#00A459";
        default:
          return "#2462d1";
      }
    },
    isDraft(): boolean {
      return this.element.status === "Черновик";
    },
    casesIds(): string[] {
      return Object.entries(this.selectedCasesIds)
        .filter(([key, value]) => !!value)
        .map(([key]) => key);
    },
    togglePdfTitle(): string {
      return this.isShowPdf ? "Закрыть предпросмотр" : "Открыть предпросмотр";
    },
    togglePdfIcon(): string {
      return this.isShowPdf ? "mdi-arrow-right" : "mdi-arrow-left";
    },
    isShowPreview(): boolean {
      return !!this.element?.pages?.length;
    },
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getCard: () => void;
          element: ReturnType<typeof convertApiItemToUi>;
          data: IActElement[];
          isLoading: boolean;
          isShowAnimation: boolean;
          isShowPdf: boolean;
        },
        value
      ) {
        if (value) {
          this.isShowPdf = false;
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getCard();
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("actsView", ["changeOpenedId", "getActElement", "saveSelectedCasesToAct", "getScreenshot"]),
    togglePdf() {
      this.isShowPdf = !this.isShowPdf;
    },
    openExportAct(type: "pdf" | "docx") {
      this.openNewWindow(getFullPath(QUERY_PATH.GET_ACT_LIST, `/export/${this.element.id.toString()}/${type}`));
    },
    async saveCases(action: string) {
      this.isSaveCasesLoading = true;
      const isSaved = await this.saveSelectedCasesToAct({ id: this.openedId, casesIds: this.casesIds, action });
      this.isSaveCasesLoading = false;
      if (isSaved) {
        this.getCard();
      }
    },
    getCard() {
      this.getActElement(this.openedId)
        .then((data: IActElement) => {
          this.element = convertApiItemToUi(data || {});
          this.selectedCasesIds = ((this.element.addedCases?.length ? this.element.addedCases : this.element.availableCasesToAdd) || []).reduce(
            (result: Record<string, boolean>, item: caseDtoItem) => {
              result[item.id] = true;
              return result;
            },
            {}
          );
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    clickElementCb(item: IModalElementType) {
      switch (item.value) {
        case fields.ACT_CASE_NAME:
          if (this.element[fields.ACT_CASE_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.$router.push({
              path: "/inventory/case",
              query: { caseNumber: this.element[fields.ACT_CASE_NUMBER as keyof ReturnType<typeof convertApiItemToUi>] as string, isOpenModal: "1" },
            });
          }
          break;
        case fields.OIK_NAME:
          if (this.element[fields.OIK_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.$router.push({
              path: "/dictionaries/oik",
              query: { name: this.element[fields.OIK_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string, isOpenModal: "1" },
            });
          }
          break;
        case fields.FUND_NAME:
          if (this.element[fields.FUND_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.$router.push({
              path: "/dictionaries/fund",
              query: {
                name: this.element[fields.FUND_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string,
                id: this.element[fields.FUND_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string,
                isOpenModal: "1",
              },
            });
          }
          break;
        case fields.NUMBER_INVENTORY:
          if (this.element[fields.NUMBER_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.$router.push({
              path: "/inventory/case-notes-list",
              query: {
                inventoryNumber: this.element[fields.NUMBER_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>] as string,
                isOpenModal: "1",
              },
            });
          }
          break;
        case fields.TITLE_INVENTORY:
          if (this.element[fields.TITLE_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.$router.push({
              path: "/inventory/case-notes-list",
              query: {
                header: this.element[fields.TITLE_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>] as string,
                isOpenModal: "1",
              },
            });
          }
          break;
        default:
          break;
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "actsView", Sections.INVENTORY, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "actsView");

    return {
      addQueryOpenedId,
    };
  },
});
