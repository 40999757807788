






















import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { PreviewStatuses } from "@monorepo/inventory/src/constants/previewStatuses";

export default defineComponent({
  name: "PreviewTableCard",
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: ModalType.EDIT,
    },
    files: {
      type: Array,
    },
    pages: {
      type: Array,
      default() {
        return [];
      },
    },
    isOnlyFile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      fileId: null as number | null | string,
      image: null,
      fileContent: "",
    };
  },
  computed: {
    isSuccessStatus() {
      return this.isOnlyFile
        ? !!this.pages?.length
        : (this.file as any)?.previewStatus?.code === PreviewStatuses.SUCCESS && (this.file as any)?.pages?.length;
    },
    file(): any {
      return this.isOnlyFile ? { pages: this.pages } : (this.files as { attachedTo?: boolean }[])?.find((file: any) => !file.attachedTo);
    },
    previewStatusText() {
      switch (this.file?.previewStatus?.code) {
        case PreviewStatuses.IN_PROGRESS:
          return "Создание изображений предпросмотра в работе";
        case PreviewStatuses.FAILED:
          return "Ошибка при создании изображений предпросмотра";
        case PreviewStatuses.DELETED:
          return "Файлы предпросмотра удалены";
        default:
          return "Предпросмотр не поддерживается";
      }
    },
    page(): string {
      const lines = this.fileContent.split("\n");
      const pages = [];
      let currentPage = "";

      for (let i = 0; i < lines.length; i++) {
        currentPage += lines[i] + "\n";

        if ((i + 1) % 20 === 0 || i === lines.length - 1) {
          pages.push(currentPage);
          currentPage = "";
        }
      }

      return pages[0];
    },
  },
  methods: {
    async getScreenshot(guid: string) {
      return await this.$store.dispatch(`${this.sectionName}/getScreenshot`, guid);
    },
    async getTxtFile(payload: Record<string, string | number>) {
      return await this.$store.dispatch(`${this.sectionName}/getTxtFile`, payload);
    },
    closeModal() {
      this.$emit("close");
    },
    downloadPdf() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
  },
  watch: {
    file: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.image = null;
        if (value?.pages?.length && value.format !== "TXT") {
          this.isLoading = true;
          this.image = await this.getScreenshot(value?.pages[0].guid);
          this.isLoading = false;
        } else if (value?.pages?.length) {
          this.isLoading = true;
          await this.getTxtFile({ uuid: value?.pages[0].guid, offset: 0, limit: 100000 })
            .then((data: string) => {
              this.fileContent = data;
            })
            .catch((error: Error) => {
              console.error("Ошибка при загрузке файла:", error);
            });
          this.isLoading = false;
        }
      },
    },
  },
});
