




























import { defineComponent } from "@vue/composition-api";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";
import TestImgPreviewMock from "../../views/EadView/components/TestImgPreviewMock.vue";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "PreviewModalNew",
  components: {
    TestImgPreview,
    TestImgPreviewMock,
    TxtViewer,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: ModalType.EDIT,
    },
    fileId: {
      type: Number,
    },
    files: {
      type: Array,
    },
    pages: {
      type: Array,
      default() {
        return [];
      },
    },
    isOnlyFile: {
      type: Boolean,
      default: false,
    },
    isShowExport: {
      type: Boolean,
      default: false,
    },
    uploadFileGuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ModalType,
    };
  },
  computed: {
    file(): { format?: string } {
      const file = (this.files as { format?: string }[])?.find((item: any) => item.id === this.fileId);
      return file || {};
    },
  },
  methods: {
    async getScreenshot(guid: string) {
      return await this.$store.dispatch(`${this.sectionName}/getScreenshot`, guid);
    },
    async getTxtFile(payload: Record<string, string | number>) {
      return await this.$store.dispatch(`${this.sectionName}/getTxtFile`, payload);
    },
    closeModal() {
      this.$emit("close");
    },
  },
});
