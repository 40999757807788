import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi, convertSearchFiltersCustomToApi } from "@monorepo/inventory/src/views/ActsView/utils/convertFiltersToApi";
import { IActElement } from "@monorepo/inventory/src/views/ActsView/types/element";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { cloneDeep } from "lodash";
import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";
import axios, { AxiosResponse } from "axios";
import { IResponse } from "@monorepo/utils/src/api/types/response";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: IActElement[];
  sectionCells: ISection;
  isOpenFilters: boolean;
  autorefresh: boolean;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  infiniteId: string;
  libraries: {
    oiks: string[];
    categories: { code: string; name: string }[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
  settingElasticValue: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    createdAt: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    [fields.FILTER_SEARCH_MORPH]: true,
  },
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  sectionCells: { id: 7 } as unknown as ISection,
  totalLength: 0,
  searchTemplates: [],
  cells: [],
  section: Sections.ACTS,
  data: [],
  isOpenFilters: false,
  autorefresh: false,
  libraries: {
    oiks: [],
    categories: [],
  },
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
  settingElasticValue: false,
  viewMode: {
    typeDisplay: ViewMode.TABLE,
  },
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_ACT_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_ACT_XLS_LIST;
    default:
      return QUERY_PATH.GET_ACT_PDF_LIST;
  }
};

export const actsView: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    refreshData(state: ITableState) {
      state.data = [];
    },
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    addOiksLib(state: ITableState, payload: string[]) {
      state.libraries.oiks = payload || [];
    },
    setElasticValue(state: ITableState, payload: boolean) {
      state.settingElasticValue = payload;
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: IActElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
    addCategoriesLib(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.categories = payload || [];
    },
  },
  actions: {
    ...baseActions,
    async getSearchAutocomplete(info, params: string) {
      if (params?.length < 3) {
        return [];
      }

      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ACT_SEARCH_AUTOCOMPLETE,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        const result = data
          .map((item: { suggestion: string }) => {
            const title = item.suggestion.replaceAll("@!!", "");
            return {
              titleValue: title,
              code: title,
            };
          })
          .filter((item: { titleValue: string }) => item.titleValue !== params);
        return [{ titleValue: params, code: params }].concat(result);
      }
      return [];
    },
    async getOiks({ commit, state }) {
      checkExistLibrary(state.libraries.oiks, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIKS_LIB);
        commit("addOiksLib", data);
      });
    },
    async getElasticValue({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_ELASTIC_SETTING);
      commit("setElasticValue", data === "true");
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IActElement[]>(
        keyword ? QUERY_PATH.GET_ACT_TMP_LIST : QUERY_PATH.GET_ACT_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      ).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: !!keyword });
        return { data: state.data || [], totalLength: totalLength || 0 };
      }
      return { data: null, error };
    },
    async getActElement(info, payload: string) {
      const { data } = await getQuery<IActElement>(`${QUERY_PATH.GET_ACT_LIST}/${payload}`);
      return data || {};
    },
    async refreshEventList({ commit, state }) {
      const filters = cloneDeep(state.filters);
      const keyword = filters.fieldFilters.keyword as unknown as { code: string };
      delete filters.fieldFilters.keyword;
      const params = convertFiltersToApi(filters, state.data.length, keyword ? convertSearchFiltersCustomToApi : convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IActElement[]>(
        keyword ? QUERY_PATH.GET_ACT_TMP_LIST : QUERY_PATH.GET_ACT_LIST,
        {
          ...params,
          ...(keyword ? { keyword: keyword?.code } : {}),
          offset: 0,
        },
        true,
        keyword ? "Семантический поиск временно не доступен." : undefined
      );
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async getAutocompleteFundByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_FUND_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    getCategories({ commit, state }) {
      try {
        checkExistLibrary(state.libraries.categories, async () => {
          const { data }: AxiosResponse<IResponse<{ code: string; name: string }[]>> = await axios.get(
            getFullPath(QUERY_PATH.GET_INVENTORIES_CATEGORIES)
          );
          commit("addCategoriesLib", data || []);
        });
      } catch (e) {
        showNotification("Ошибка получения списка видов описи.");
      }
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_ACT_PDF_LIST_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
    // async getTaskAutocompleteByTitle(info, value: string) {
    //   const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_TASKS_AUTOCOMPLETE_BY_TITLE + `/${value}`);
    //   if (data !== null) {
    //     return data.map((item: { title: string }) => ({
    //       ...item,
    //       titleValue: item.title,
    //     }));
    //   }
    //   return [];
    // },
    // async getTaskAutocompleteByNumber(info, value: string) {
    //   const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_TASKS_AUTOCOMPLETE_BY_NUMBER + `/${value}/like`);
    //   if (data !== null) {
    //     return data.map((item: { number: number }) => ({
    //       ...item,
    //       titleValue: item.number,
    //     }));
    //   }
    //   return [];
    // },
    async getEadTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<any[]>(`${QUERY_PATH.GET_EAD_BY_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async saveSelectedCasesToAct(info, { id, casesIds, action }: { id: string | number; casesIds: number[]; action: "SAVE" | "ACCEPT" }) {
      try {
        await axios.post(`${getFullPath(QUERY_PATH.FORM_INVNTORY_ACT)}/${id}`, { caseIds: casesIds, actAction: action });
        showNotification("Акт приёма-передачи дел успешно сохранён", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification(e.response?.data?.message || "Ошибка сохранения акта приёма-передачи дел");
        console.error(e);
        return false;
      }
    },
  },
  getters: {
    ...baseGetters,
    oiksLibrary(state: ITableState) {
      return state.libraries.oiks;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    categoriesLibrary(state: ITableState) {
      return state.libraries.categories || [];
    },
    elasticValue(state: ITableState) {
      return state.settingElasticValue;
    },
  },
};
