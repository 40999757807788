import { fields } from "@monorepo/utils/src/variables/projectsData/eadView/fields";

export const searchMenuItems = [
  {
    value: fields.FILTER_SEARCH_MORPH,
    title: "морфология",
    color: "yellow",
  },
  {
    value: fields.FILTER_SEARCH_NGRAMED,
    title: "частичное совпадение",
    color: "#66e3ff",
  },
  {
    value: fields.FILTER_SEARCH_SYNONYM,
    title: "синонимы",
    color: "#77fc7b",
  },
  {
    value: fields.FILTER_SEARCH_FREQUENCY,
    title: "частота упоминания",
    color: "#f492fc",
  },
];
