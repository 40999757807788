<template>
  <div class="wrap">
    <v-app-bar color="#323639" dark>
      <v-toolbar-title class="pdf__title ml-3">123123.pdf</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :disabled="currentPage === 1" @click="moveToPage(1)">
        <v-icon size="14">mdi-arrow-collapse-left</v-icon>
      </v-btn>
      <v-btn width="22" height="22" icon :disabled="currentPage === 1" @click="moveToPage(currentPage - 1)">
        <v-icon size="14">mdi-arrow-left</v-icon>
      </v-btn>
      <div class="pdf__white ml-1 mr-1">{{ currentPage }} / {{ numPages }}</div>
      <v-btn width="22" height="22" icon :disabled="currentPage === numPages" @click="moveToPage(currentPage + 1)">
        <v-icon size="14">mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn width="22" height="22" icon :disabled="currentPage === numPages" @click="moveToPage(numPages)">
        <v-icon size="14">mdi-arrow-collapse-right</v-icon>
      </v-btn>
      <v-btn class="ml-6" width="22" height="22" icon @click="scale -= scale > 0 ? 0.1 : 0">
        <v-icon size="18">mdi-minus</v-icon>
      </v-btn>
      <v-text-field outlined dense hide-details class="pdf__scale-input" v-model="scaleInput" :min="1" :max="100"></v-text-field>
      <v-btn width="22" height="22" icon @click="scale += scale < 2 ? 0.1 : 2">
        <v-icon size="18">mdi-plus</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div class="pdf-container">
      <div class="pdf-container__thumbnails">
        <div class="thumbnails">
          <div class="thumbnails__item" v-for="(image, index) in images" :key="index">
            <v-card @click="moveToPage(index + 1)">
              <v-img
                :class="['v-img', { 'active-img': currentPage === index + 1 }]"
                :src="baseURL + image.url"
                contain
                :style="{ height: '80px' }"
              ></v-img>
              <div class="index">{{ index + 1 }}</div>
            </v-card>
          </div>

          <infinite-loading ref="infiniteLoadingThumbs" @infinite="loadMore" :distance="10">
            <div slot="no-more"></div>
          </infinite-loading>
        </div>
      </div>
      <div class="pdf-container__content">
        <div class="pdf-pages" id="pdf-pages" @wheel="changePage($event)">
          <!-- <div class="pdf-pages__item" v-for="(image, index) in items" :key="image.id">
            <div :ref="`page-${index + 1}`" :style="{ width: `${scale * 100}%`, height: '100%', 'object-fit': 'contain' }">
              <img :src="image.url" :style="{ width: '100%' }" />
            </div>
          </div> -->
          <div
            class="pdf-pages__item"
            :ref="`page-${index + 1}`"
            v-for="(image, index) in images"
            :key="image.id"
            :style="{ width: `${scale * 100}%`, 'object-fit': 'contain' }"
          >
            <OrcPage :imageUrl="image.url" :hocrText="response.ocrPages[index].hocrText" />
          </div>
          <!-- <OrcPage /> -->
          <infinite-loading ref="infiniteLoading" @infinite="loadMore" :distance="10">
            <div slot="no-more"></div>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { response as mockResponse } from "../utils/testResponse";
import OrcPage from "./DocumentsView/OrcPage_documents-view.vue";

export default {
  name: "InfiniteScrollImages",
  data() {
    return {
      loadedItems: 0,
      page: 0,
      numPages: 20,
      response: null,
      baseURL: "http://192.168.88.155:12084/nlp-demo",
      scale: 0.7,
      currentPage: 1,
    };
  },
  computed: {
    images() {
      return this.response ? this.response.resultImages.slice(0, this.loadedItems) : [];
    },
    scaleInput: {
      get() {
        return Math.trunc(this.scale * 100);
      },
      set(val) {
        this.scale = +val / 100;
      },
    },
  },
  created() {
    this.numPages = mockResponse.data.ocrPages.length;
    this.response = mockResponse.data;
  },
  methods: {
    async moveToPage(page) {
      while (page > this.loadedItems) {
        const $state = this.$refs.infiniteLoading.stateChanger;
        await this.loadMore($state);
      }

      this.$refs[`page-${page}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.currentPage = page;
    },
    changePage(event) {
      const scroll = document.getElementById("pdf-pages").scrollTop;

      if (this.numPages === 1) {
        return;
      }

      if (!this.$refs[`page-${this.currentPage}`]?.[0]) {
        return;
      }

      if (this.currentPage !== 1 && scroll <= (this.$refs[`page-${this.currentPage}`]?.[0].offsetTop || 0)) {
        this.currentPage -= 1;
        return;
      }

      if (this.currentPage < this.numPages && scroll > (this.$refs[`page-${this.currentPage + 1}`]?.[0].offsetTop || 0)) {
        this.currentPage += 1;
      }
    },
    async loadMore($state) {
      console.log("error");
      if (this.loadedItems >= this.numPages) {
        $state.complete();
        return;
      }

      this.loadedItems = this.numPages > this.loadedItems + 4 ? this.loadedItems + 4 : this.numPages;
      $state.loaded();
    },
  },
  components: {
    InfiniteLoading,
    // eslint-disable-next-line vue/no-unused-components
    OrcPage,
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  background: #525659;
}

.pdf-page-buttons {
  background-color: #000;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 100;
}

.pdf-page-buttons__title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pdf__white {
  color: #fff;
  font-size: 14px;
  margin: 0 10px;
}

.thumbnails {
  overflow-y: auto;
  width: 100%;
  background: #323639;
  height: 100%;
  padding: 5px;

  &__item {
    margin-bottom: 6px;
  }
}

.thumbnails .v-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 70%;
  background: transparent;
  box-shadow: none;
  margin: 0 auto;
}

.thumbnails .v-img {
  height: 100%;
  width: auto;
  opacity: 0.5;

  &.active-img {
    opacity: 1;
  }
}

.index {
  color: #fff;
  font-size: 12px;
  margin-top: 5px;
}

button {
  color: #fff !important;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  width: 40px;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
}

input:focus {
  outline: none;
}

.pdf-container {
  width: 100%;
  height: calc(100% - 64px);
  display: flex;

  &__thumbnails {
    width: 200px;
  }

  &__content {
    flex: 1;
  }
}

.pdf-col,
.pdf-row {
  height: 100%;
}

.pdf-pages {
  height: 100%;
  gap: 12px;
  overflow: auto;
  position: relative;

  &__item {
    display: flex;
    justify-content: center;
    margin: 0 auto 12px;
  }
}

.pdf__title {
  font-size: 14px;
}

.pdf__scale-input {
  max-width: 50px;
  font-size: 14px;
}
</style>
