import { fields } from "./fields";
import { searchMenuItems } from "@monorepo/inventory/src/views/EadView/constants/searchMenuItems";

export const filtersElements = [
  {
    value: fields.FILTER_KEYWORD,
    //title: "Поиск",
    placeholder: "Поисковый запрос",
    tooltip: "Ручной ввод, поиск по маске",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getSearchAutocomplete",
    className: "filters__full-block",
    appendInnerIcon: "mdi-cog",
    iconTooltip: "Найденные фрагменты будут подсвечены в результатах поиска соответствующим найстройке поиска цветом",
    menuItems: searchMenuItems,
  },
  {
    value: fields.FILTER_NUMBER_ACT,
    title: "№ акта",
    tooltip: "текстовый ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_NUMBER_ERC_PROTOCOL,
    title: "Номер описи дел",
    tooltip: "текстовый ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_TITLE_ERC_PROTOCOL,
    title: "Заголовок описи дел",
    tooltip: "текстовый ввод, поиск по маске",
    type: "text",
    width: 250,
  },
  {
    value: fields.FILTER_CATEGORY_ERC_PROTOCOL,
    title: "Вид описи дел",
    tooltip: "выбор из списка",
    type: "select",
    selectItemsField: "categories",
    width: 250,
  },
  {
    value: fields.FILTER_OIK,
    title: "ОИК",
    tooltip: "предиктивный ввод",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 300,
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "предиктивный ввод",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getAutocompleteFundByCode",
    width: 300,
  },
  {
    value: fields.FILTER_PERIOD,
    dateValues: [fields.FILTER_PERIOD_FROM, fields.FILTER_PERIOD_TO],
    title: "Период формирование акта",
    tooltip: "выбор интервала с помощью календаря",
    type: "datePeriod",
    width: 250,
  },
];
