import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  formDateFilter(resultFilters, fields.FILTER_PERIOD);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.ACT_OIK_CODE}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.code`);
  delete resultFilters.fieldFilters.keyword;
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_MORPH];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_NGRAMED];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_SYNONYM];
  delete resultFilters.fieldFilters[fields.FILTER_SEARCH_FREQUENCY];
};

export const convertSearchFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  formDateFilter(resultFilters, fields.FILTER_PERIOD);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_OIK, `fieldFilters.${fields.ACT_OIK_CODE}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.FILTER_FUND, `fieldFilters.${fields.FILTER_FUND}.code`);
};
