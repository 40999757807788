import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.ACT_OIK_NAME]: {},
  [fields.ACT_OIK_CODE]: {},
  [fields.ACT_DATE_FROM]: "",
  [fields.ACT_DATE_TO]: "",
  [fields.ACT_NUMBER]: "",
  [fields.ACT_FORM_DATE_FROM]: "",
  [fields.ACT_FORM_DATE_TO]: "",
  [fields.ACT_CASE_NUMBER]: {},
  [fields.ACT_CASE_NAME]: {},
  [fields.FILTER_CATEGORY_ERC_PROTOCOL]: [],
});
