<template>
  <section class="orc-documents-view">
    <!-- <img :src="baseURL + imageUrl" alt class="orc-documents-view__image-view" ref="layoutContainer"/> -->
    <!-- <div v-html="hocrText" class="orc-documents-view__hocr-html" ref="editorContainer"></div> -->
    <div :id="'layout-container' + imageUrl" class="orc-documents-view__image-view"></div>
    <div :id="'editor-container' + imageUrl" class="orc-documents-view__hocr-html"></div>
  </section>
</template>
<script>
import { HocrProofreader } from "./hocrRroofreader";
const baseURL = "http://192.168.88.155:12084/nlp-demo";
export default {
  name: "orc-documents-view",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    hocrText: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function () {
    return {
      imageSrc: undefined,
      baseURL: baseURL,
      hocrProofreader: null,
    };
  },
  mounted() {
    this.hocrProofreader = new HocrProofreader({
      layoutContainer: "layout-container" + this.imageUrl,
      editorContainer: "editor-container" + this.imageUrl,
    });
    this.hocrProofreader.setHocr(this.hocrText, baseURL, this.baseURL + this.imageUrl, "Федерации");
  },
};
</script>

<style lang="scss">
@import "./hocr-editor";
@import "./hocr-proofreader";

.orc-documents-view {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;

  &__hocr-html {
    width: 0;
    min-height: 500px;

    & iframe {
      display: none;
      width: 100%;
      min-height: 500px;
    }
  }

  &__image-view {
    width: 100%;
    min-height: 500px;
    overflow: hidden !important;
  }
}
</style>
