export interface IActsFields {
  ACT_ID: string;
  ACT_OIK_NAME: string;
  ACT_OIK_CODE: string;
  ACT_DATE: string;
  ACT_DATE_FROM: string;
  ACT_DATE_TO: string;
  ACT_NUMBER: string;
  ACT_FORM_DATE: string;
  ACT_FORM_DATE_FROM: string;
  ACT_FORM_DATE_TO: string;
  ACT_CASE_NUMBER: string;
  ACT_CASE_NAME: string;
  ACT_CASE_TYPE: string;
  ACT_CASE_KIND: string;
  ACT_CASE_YEAR: string;
  ACT_FUND_NAME: string;
  ACT_FUND_ID: string;

  STATUS: string;
  NUMBER_INVENTORY: string;
  YEAR_INVENTORY: string;
  TITLE_INVENTORY: string;
  OIK_NAME: string;
  FUND_NUMBER: string;
  AK_CREATE_DATE: string;
  TYPE_INVENTORY: string;
  INVENTORY_CATEGORY: string;
  FUND_NAME: string;
  HIGHLIGHTS: string;

  DOCUMENTS_NUMBER: string;
  DOCUMENTS_TITLE: string;
  DOCUMENTS_ARCHIVAL_CIPHER: string;
  DOCUMENTS_ARCHIVAL_CIPHER_CASE: string;
  DOCUMENTS_INDEX_CASE: string;
  DOCUMENTS_TITLE_CASE: string;
  DOCUMENTS_DATE_ARRIVAL: string;
  DOCUMENTS_CHIPBOARD: string;

  FILTER_KEYWORD: string;
  FILTER_SEARCH_MORPH: string;
  FILTER_SEARCH_NGRAMED: string;
  FILTER_SEARCH_SYNONYM: string;
  FILTER_SEARCH_FREQUENCY: string;
  FILTER_NUMBER_ACT: string;
  FILTER_NUMBER_ERC_PROTOCOL: string;
  FILTER_TITLE_ERC_PROTOCOL: string;
  FILTER_CATEGORY_ERC_PROTOCOL: string;
  FILTER_OIK: string;
  FILTER_FUND: string;
  FILTER_PERIOD: string;
  FILTER_PERIOD_FROM: string;
  FILTER_PERIOD_TO: string;
}

export const fields: IActsFields = {
  ACT_ID: "id",
  ACT_OIK_NAME: "oikName",
  ACT_OIK_CODE: "oikCode",
  ACT_DATE: "date",
  ACT_DATE_FROM: "dateFrom",
  ACT_DATE_TO: "dateTo",
  ACT_NUMBER: "number",
  ACT_FORM_DATE: "formDate",
  ACT_FORM_DATE_FROM: "formDateFrom",
  ACT_FORM_DATE_TO: "formDateTo",
  ACT_CASE_NUMBER: "caseNumber",
  ACT_CASE_NAME: "caseName",
  ACT_CASE_TYPE: "caseType",
  ACT_CASE_KIND: "caseKind",
  ACT_CASE_YEAR: "caseYear",
  ACT_FUND_NAME: "fundName",
  ACT_FUND_ID: "fundId",

  STATUS: "status",
  NUMBER_INVENTORY: "inventoryNumber",
  YEAR_INVENTORY: "inventoryYear",
  TITLE_INVENTORY: "inventoryHeader",
  OIK_NAME: "oikName",
  FUND_NUMBER: "fundNumber",
  AK_CREATE_DATE: "createDate",
  TYPE_INVENTORY: "inventoryKind",
  INVENTORY_CATEGORY: "inventoryCatehory",
  FUND_NAME: "fundName",
  HIGHLIGHTS: "highlights",

  DOCUMENTS_NUMBER: "tmp10",
  DOCUMENTS_TITLE: "tmp11",
  DOCUMENTS_ARCHIVAL_CIPHER: "tmp12",
  DOCUMENTS_ARCHIVAL_CIPHER_CASE: "tmp13",
  DOCUMENTS_INDEX_CASE: "tmp14",
  DOCUMENTS_TITLE_CASE: "tmp15",
  DOCUMENTS_DATE_ARRIVAL: "tmp16",
  DOCUMENTS_CHIPBOARD: "tmp17",

  FILTER_KEYWORD: "keyword",
  FILTER_SEARCH_MORPH: "morphology",
  FILTER_SEARCH_NGRAMED: "ngramed",
  FILTER_SEARCH_SYNONYM: "synonymysed",
  FILTER_SEARCH_FREQUENCY: "frequency-term",
  FILTER_NUMBER_ACT: "number",
  FILTER_NUMBER_ERC_PROTOCOL: "inventoryNumber",
  FILTER_TITLE_ERC_PROTOCOL: "inventoryHeader",
  FILTER_CATEGORY_ERC_PROTOCOL: "category",
  FILTER_OIK: "oikCode",
  FILTER_FUND: "fundCode",
  FILTER_PERIOD: "createdAt",
  FILTER_PERIOD_FROM: "createdAtFrom",
  FILTER_PERIOD_TO: "createdAtTo",
};
