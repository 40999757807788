import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Статус",
    tooltip: "Статус акта приёма-передачи дел может принимать значения: Отправлен в ОИК, Согласован в ОИК",
    defaultWidth: 220,
    value: fields.STATUS,
    sortField: "status",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "№ акта",
    tooltip: "Номер акта приёма-передачи дел",
    defaultWidth: 142,
    value: fields.ACT_NUMBER,
    isSorted: true,
    isHandle: true,
    sortField: "number",
    id: uuid(),
  },
  {
    text: "Номер описи дел",
    tooltip: "Номер описи дел по которой сформирован акт приёма-передачи дел",
    defaultWidth: 220,
    isLink: true,
    value: fields.NUMBER_INVENTORY,
    sortField: "inventory.number",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Год описи дел",
    tooltip: "Год описи дел",
    defaultWidth: 220,
    sortField: "inventory.year",
    value: fields.YEAR_INVENTORY,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Заголовок описи дел",
    tooltip: "Заголовок описи дел, по которой сформирован акт приёма-передачи дел",
    defaultWidth: 220,
    isLink: true,
    sortField: "inventory.header",
    value: fields.TITLE_INVENTORY,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Вид описи дел",
    tooltip: "Вид описи дел может принимать значения Больше или равно 10 лет, Постоянно, По личному составу	",
    defaultWidth: 220,
    sortField: "inventory.kind",
    value: fields.INVENTORY_CATEGORY,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "ОИК",
    tooltip: "Краткое наименование ОИК",
    isLink: true,
    sortField: "oikShortName",
    linkRights: [authorities.OIK_LIST],
    defaultWidth: 230,
    value: fields.OIK_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "№ фонда",
    tooltip: "Номер фонда",
    isLink: true,
    linkRights: [authorities.FUND_LIST],
    defaultWidth: 230,
    sortField: "fundNumber",
    value: fields.FUND_NUMBER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование фонда",
    tooltip: "Краткое наименование фонда",
    isLink: true,
    linkRights: [authorities.FUND_LIST],
    defaultWidth: 230,
    sortField: "fundName",
    value: fields.FUND_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата формирования акта",
    tooltip: "Дата формирования акта приёма-передачи дел",
    defaultWidth: 220,
    value: fields.AK_CREATE_DATE,
    sortField: "createdAt",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
