import { IActElement } from "@monorepo/inventory/src/views/ActsView/types/element";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";
import moment from "moment";

export const convertApiItemToUi = (element: IActElement): IActElement => ({
  ...element,
  [fields.ACT_ID]: element.id,
  [fields.STATUS]: element.id === 34 ? "Черновик" : element.status,
  [fields.ACT_NUMBER]: element.number,
  [fields.NUMBER_INVENTORY]: element.inventory?.number,
  [fields.YEAR_INVENTORY]: element.inventory?.year,
  [fields.TITLE_INVENTORY]: element.inventory?.header,
  [fields.INVENTORY_CATEGORY]: element.inventory?.category?.title || "",
  [fields.OIK_NAME]: element.oik?.shortName,
  [fields.FUND_NUMBER]: element.fund?.number,
  [fields.FUND_NAME]: element.fund?.name,
  [fields.AK_CREATE_DATE]: moment(element.createdAt).format("YYYY-MM-DD HH:mm"),
  [fields.HIGHLIGHTS]: element.highlightFields,
  mrdPowerAttorneyNumber: element.mrdDto?.powerAttorneyNumber || "",
  mrdCreationDate: element.mrdDto?.creationDate ? moment(element.mrdDto.creationDate).format("YYYY-MM-DD HH:mm") : "",
  mrdExpiredAt: element.mrdDto?.expiredAt ? moment(element.mrdDto.expiredAt).format("YYYY-MM-DD HH:mm") : "",
  mrdTrusteeCompanyName: element.mrdDto?.trusteeCompanyName || "",
  mrdTrusteeInn: element.mrdDto?.trusteeInn || "",
  mrdTrusteeOgrn: element.mrdDto?.trusteeOgrn || "",
  mrdTrusteeFio: element.mrdDto?.trusteeFio || "",
  mrdRepresentativeFio: element.mrdDto?.representativeFio || "",
  mrdPermissions: element.mrdDto?.permissionText || "",
});
