




















































import { defineComponent, PropType, ComponentInstance, computed } from "@vue/composition-api";
import { IDigitalSignatures } from "../../EadView/types/eadElement";
import moment from "moment";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

const tableHeaders = [
  { width: 160, defaultWidth: 120, value: "isValid", text: "Статус", tooltip: "Статус ЭП (валидна/не валидна) в виде значка" },
  { width: 116, defaultWidth: 200, value: "commonName", text: "Кому выдан", tooltip: "Фамилия имя и отчество владельца сертификата" },
  {
    width: 236,
    defaultWidth: 200,
    value: "organizationName",
    text: "Кем выдан",
    tooltip: "Наименование юридического лица АУЦ, выдавшего сертификат",
  },
  { width: 160, defaultWidth: 120, value: "serNum", text: "Серийный номер", tooltip: "Серийный номер сертификата" },
  { width: 116, defaultWidth: 200, value: "expiredAt", text: "Действителен", tooltip: "Дата окончания действия сертификата" },

  { width: 236, defaultWidth: 200, value: "signTime", text: "Дата подписания", tooltip: "Дата подписания файла электронной подписью" },
  { width: 160, defaultWidth: 150, value: "timeStamp", text: "Штамп времени", tooltip: "Штамп времени" },
  {
    width: 116,
    defaultWidth: 200,
    value: "tsSerNum",
    text: "Серийный номер сервера штампа времени",
    tooltip: "Серийный номер сервера штампа времени",
  },
];

export default defineComponent({
  name: "DigitalSignaturesTable",
  props: {
    digitalSignatures: {
      type: Array as PropType<IDigitalSignatures[]>,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableHeaders,
    };
  },
  computed: {
    resultDigitalSignatures(): IDigitalSignatures[] {
      return (this.digitalSignatures as IDigitalSignatures[]).map((signature) => ({
        ...signature,
        expiredAt: signature.expiredAt ? moment(signature.expiredAt).format("DD-MM-YYYY HH:mm") : "-",
        signTime: signature.signTime ? moment(signature.signTime).format("DD-MM-YYYY HH:mm") : "-",
        timeStamp: signature.timeStamp ? moment(signature.timeStamp).format("YYYY-MM-DD HH:mm") : "",
      }));
    },
  },
  methods: {
    onScrollCb(e: Event) {
      const element = document.getElementById("signature-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const resultTableHeaders = computed(() => tableHeaders as unknown[]);
    const { headers } = useTableCells(root, "", resultTableHeaders);
    return {
      headers,
    };
  },
});
