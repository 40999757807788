















































import { defineComponent, ComponentInstance } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import EadStatus from "@monorepo/inventory/src/views/EadView/components/EadStatus.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import { IDigitalSignatures, IEadDocumentFiles } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import { useDynamicTree } from "@monorepo/utils/src/composables/useDynamicTree";

export interface caseDtoItem {
  oik: { id: number; code: string; name: string };
  oikCode: string;
  receivedEadCount: number;
  totalEadCount: number;
  year: string;
  fundCode: string;
  index: number;
  guid: string;
  header: string;
  id: number;
  number: string;
  archiveCode: string;
  archiveCipher: string;
  date: string;
  digitalSignatures: IDigitalSignatures[];
  ead: {
    createDate: string;
    digitalSignatures: IDigitalSignatures[];
    files: IEadDocumentFiles[];
    guid: string;
    number: number;
    id: number;
    title: string;
    archiveCipher: string;
    oik: { id: number; code: string; name: string };
    registrationDate: string;
    status: string;
    storageExpirationDate: string;
    storagePeriod: number;
  }[];
}

export default defineComponent({
  name: "CaseTree",
  components: {
    TreeNode,
    EadStatus,
    InventoryStatus,
    InfiniteScroll,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    cardModeResult: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCanCheck: {
      type: Boolean,
      default: false,
    },
    isShowActInfo: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [] as caseDtoItem[],
    },
  },
  computed: {
    headers(): Record<
      string,
      { key: string; title: string; tooltip: string; width: string; isNoWrap?: boolean; slotName?: string; isLink?: boolean }[]
    > {
      const documentNumber = this.cardModeResult === CardMode.DEFAULT ? "70px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "250px" : "300px";
      const documentStatus = this.cardModeResult === CardMode.DEFAULT ? "50px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "70px" : "70px";
      const documentName = this.cardModeResult === CardMode.DEFAULT ? "164px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "258px" : "400px";

      const caseName = this.cardModeResult === CardMode.DEFAULT ? "100px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "200px" : "200px";
      const caseArchive = this.cardModeResult === CardMode.DEFAULT ? "126px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "300px" : "300px";

      return {
        case: [
          { key: "doc", slotName: "doc", title: "Документы", tooltip: "Получено документов/Всего документов", width: "100px" },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр", width: caseArchive },
          { key: "name", title: "Заголовок", tooltip: "Заголовок дела", width: caseName, isLink: true },
          { key: "fundNumber", title: "Фонд", tooltip: "Номер фонда", width: "100px" },
          { key: "index", title: "Индекс дела", tooltip: "Индекс дела", width: "100px" },
          { key: "storageKind", title: "Вид", tooltip: "Вид дела", width: "100px" },
          { key: "numberOfPages", title: "Листов", tooltip: "Количество листов", width: "100px" },
          { key: "status", title: "Статус", tooltip: "Статус", width: "120px" },
          ...(this.isShowActInfo
            ? [
                { key: "actNumber", title: "Номер акта", tooltip: "Номер акта", width: "100px" },
                { key: "actDate", title: "Дата акта", tooltip: "Дата формирования акта", width: "100px" },
              ]
            : []),
        ],
        document: [
          { key: "checkbox", slotName: "checkbox", title: "", tooltip: "", width: "30px" },
          { key: "number", title: "Номер", tooltip: "Порядковый номер ЭАД", width: documentNumber, isNoWrap: false },
          {
            key: "status",
            title: "Статус",
            slotName: "status",
            tooltip: "Статус ЭАД может принимать следующие значения: Получен, Не получен",
            width: documentStatus,
            isNoWrap: true,
          },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр", width: "120px", isNoWrap: false },
          { key: "name", title: "Наименование", tooltip: "Наименование ЭАД", width: documentName, isNoWrap: true, isLink: true },
          { key: "regNumber", title: "Рег. номер", tooltip: "Регистрационный номер ЭАД в СЭД", width: "100px", isNoWrap: true },
          { key: "date", title: "Дата регистрации в СЭД", tooltip: "Дата регистрации в СЭД", width: "160px", isNoWrap: true },
        ],
      };
    },
  },
  data() {
    return {
      open: [],
      active: [],
      folder: `${environmentVariables.BASE_URL}assets/images/common/foleder.svg`,
      treeItems: [] as any[],
    };
  },
  methods: {
    onChange(id: string, value: boolean) {
      this.$emit("change", {
        ...((this.value as Record<string, boolean>) || {}),
        [id]: value,
      });
    },
    async fetchChildren(item: { totalLength: number; children: unknown[]; isCase: boolean; id: string | number }) {
      this.openedItems = [...this.openedItems, item];
      if (item.totalLength && item.totalLength <= item.children.length) {
        return;
      }

      if (item.isCase) {
        await this.fetchEads(item);
      }
    },
    openInventory({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case",
          query: {
            header: item.name,
            isOpenModal: "1",
          },
        });
      }
    },

    openEad({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        if (header.isLink) {
          this.$router.push({ path: "/inventory/ead", query: { title: item.name, isOpenModal: "1" } });
        }
      }
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.treeItems = (value || []).map((item: any, index: number) => ({
          id: item.id,
          title: item.number,
          isCase: true,
          isFolder: true,
          caseId: `${item.id}${index}`,
          doc: `${item.receivedEadCount} / ${item.totalEadCount}`,
          name: item.header,
          receivedEadCount: item.receivedEadCount,
          totalEadCount: item.totalEadCount,
          receivedPaperCount: item.receivedPaperCount,
          totalPaperCount: item.totalPaperCount,
          archive: item.archiveCipher,
          fundNumber: item.fundCode,
          index: item.index,
          // children: [],
          storageKind: item.storageKind?.title || "",
          numberOfPages: item.numberOfPages || "",
          status: item.status?.title || "",
          actNumber: item.actNumber,
          actDate: item.actDate,
        }));
      },
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const fetchEads = async (item: { children: unknown[]; id: string | number; totalLength: number; isCase?: boolean }) => {
      const { data, totalLength } = await root.$store.dispatch("actsView/getEadTreeData", {
        length: item.children?.length || 0,
        id: item.id,
      });

      item.totalLength = totalLength;
      item.children = [
        ...(item.children || []),
        ...data.map((eadTreeItem: Record<string, unknown>, index: number) => {
          return {
            id: index,
            isDoc: true,
            number: eadTreeItem.number,
            name: eadTreeItem.title,
            archive: eadTreeItem.archiveCipher,
            status: eadTreeItem.status,
            date: eadTreeItem.registrationDate,
            caseIndex: eadTreeItem.caseIndex,
            regNumber: eadTreeItem.id,
          };
        }),
      ].map((item, index) => {
        return {
          ...item,
          isCommonHeader: !index,
          isHideHeader: index,
        };
      });
    };

    const handleLoad = (item: { children: unknown[]; id: string | number; totalLength: number; isCase?: boolean }) => {
      if (item.totalLength && item.totalLength <= item.children.length) {
        return;
      }

      if (item.isCase) {
        fetchEads(item);
      }
    };

    const { isLoadingOpenedItems, openedItems } = useDynamicTree({ handleLoad });

    return {
      isLoadingOpenedItems,
      openedItems,
      fetchEads,
    };
  },
});
