import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "№ акта",
    tooltip: "Номер акта передачи дел",
    className: "info-modal__element_title",
    value: fields.ACT_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Номер описи дел",
    tooltip: "Номер описи дел по которой сформирован акт приёма-передачи дел",
    className: "info-modal__element_title",
    value: fields.NUMBER_INVENTORY,
    activeLink: true,
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год описи дел",
    tooltip: "Год описи дел",
    className: "info-modal__element_title",
    value: fields.YEAR_INVENTORY,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок описи дел",
    tooltip: "Заголовок описи дел, по которой сформирован акт приёма-передачи дел",
    className: "info-modal__element_title",
    value: fields.TITLE_INVENTORY,
    activeLink: true,
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид описи дел",
    tooltip: "Вид описи дел может принимать значения Больше или равно 10 лет, Постоянно, По личному составу	",
    className: "info-modal__element_title",
    value: fields.INVENTORY_CATEGORY,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОИК",
    tooltip: "Краткое наименование ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_NAME,
    activeLink: true,
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ фонда",
    tooltip: "Номер фонда",
    className: "info-modal__element_title",
    value: fields.FUND_NUMBER,
    activeLink: true,
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование фонда",
    tooltip: "Краткое наименование фонда",
    className: "info-modal__element_title",
    value: fields.FUND_NAME,
    activeLink: true,
    isLink: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата формирования акта",
    tooltip: "Дата формирования акта приёма-передачи дел",
    className: "info-modal__element_title",
    value: fields.AK_CREATE_DATE,
  },
];

export const mrpElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Номер доверенности",
    tooltip: "Номер доверенности",
    className: "info-modal__element_title",
    value: "mrdPowerAttorneyNumber",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата доверенности",
    tooltip: "Дата доверенности",
    className: "info-modal__element",
    value: "mrdCreationDate",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Срок действия",
    tooltip: "Срок действия",
    className: "info-modal__element",
    value: "mrdExpiredAt",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование доверителя",
    className: "info-modal__element_title",
    value: "mrdTrusteeCompanyName",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ИНН",
    tooltip: "ИНН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeInn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОГРН",
    tooltip: "ОГРН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeOgrn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    className: "info-modal__element_title",
    value: "mrdTrusteeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО представителя",
    tooltip: "ФИО представителя",
    className: "info-modal__element_title",
    value: "mrdRepresentativeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Полномочия представителя по доверенности",
    tooltip: "Полномочия представителя по доверенности",
    className: "info-modal__element_title",
    value: "mrdPermissions",
    isShowContentTooltip: true,
  },
];

export const fullModalElements = [...modalElements, ...mrpElements];
